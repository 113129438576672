[class*="carouselWrapper"] {
  .carousel {
    .carousel-indicators {
      display: none;
    }

    .carousel-control-prev {
      display: none;
    }

    .carousel-control-next {
      display: none;
    }
  }
}